.fixed-button {
  z-index: 100;
  position: fixed;
  right: 1.5rem;
  font-size: 1rem;
  line-height: 1.3rem;
  padding: 0.6rem 0.6rem;
  color: $global-font-secondary-color;
  // background: $global-border-color; // 原 light $global-border-color dark,black $header-background-color
  background: $header-background-color;
  @include border-radius(2rem);
  @include blur;
  &:hover,
  &:active {
    color: $global-font-color;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
}

#back-to-top-button {
  display: block;
  bottom: 4.5rem;
}

#view-comments {
  bottom: 1.5rem;
}

.katex-display {
  overflow: auto hidden;
}
.katex-display > .katex {
  white-space: normal;
}
.katex-display > .base {
  margin: 0.25em 0;
}
.katex-display {
  margin: 0.5em 0;
}
